import { CountryOption, RegistrationWall } from '@/components';
import { LIST_FIELD_NOT_RENDER_TO_LINE, RouteName } from '@/constants';
import { useGlobalState } from '@/hooks';
import {
  type IChart,
  type IChartCarrierInfo,
  type IChartData,
  type IModifiedChartDataToLineMetrics,
  KeysCanBeCompared,
  LineChartType,
  LineTitleType,
  PmAnalyticsQueryTable,
} from '@/interfaces';
import {
  formatFloatingNumber,
  generateMockMetricsData,
  getCarrierInfoByName,
  getChartLabels,
  getIdenticalTimeframe,
  getMarketSlugFromMarketName,
  getRenderConditionsForChart,
  getTradelane,
  getTradelaneFromInput,
  replacePath,
} from '@/utils';
import { type FC, Fragment, useEffect, useRef, useState } from 'react';
import ChartContainer from '../ChartContainer';
import CarrierName from './Carrier';
import LineItem from './LineItem';
import { ChildContainer, LineChildOuterContainer, LineItemWrapper, Timeframe, YearText } from './LineMetrics.styles';
import Skeleton from './Skeleton';
import TradeLane from './TradeLane';

const LineMetrics: FC<IChart> = (props) => {
  const { minimumRecord, ...chart } = props;
  const { registrationWall: child, title, type, showDescription, query } = chart;
  const { hasActualData: hasData, shouldRenderInnerComponent } = getRenderConditionsForChart({
    data: props.data,
    minimumRecord,
    child,
    Component: RegistrationWall,
  });
  const data = hasData ? props.data : generateMockMetricsData(chart?.query);
  const { carriers } = useGlobalState();

  const firstDataRef = useRef<HTMLDivElement>(null);
  const childRef = useRef<HTMLDivElement>(null);
  const clonedRowsRef = useRef<HTMLDivElement | null>(null);
  const [clonedRowArray, setClonedRowArray] = useState<Array<React.JSX.Element | null>>([]);

  const Component = RegistrationWall as any;
  const timeframe = getIdenticalTimeframe(data);
  const tradelane = getTradelaneFromInput(query?.input);

  useEffect(() => {
    if (childRef.current && shouldRenderInnerComponent) {
      // calculate height of first data, including margin-top
      const firstDataRefStyle = firstDataRef.current && window.getComputedStyle(firstDataRef.current);
      const marginTop = Number(firstDataRefStyle?.getPropertyValue('margin-top')?.split('px')[0] || 0);
      const firstDataRefHeight = firstDataRef.current && firstDataRef.current.clientHeight + marginTop;
      // get the height of the first component, e.g. get 81px (minimum 70)
      const height = firstDataRefHeight || 70;
      // calculate the height of the childRef, e.g. calculate height of Paywall
      const childRefHeight = childRef.current.clientHeight;
      // calculate how many rows are needed to fill the childRef
      const realDataLength = props.data?.length || 0;
      const baseBlurredRows = (minimumRecord || 0) - realDataLength;
      const minimumBlurredRows = Math.ceil(childRefHeight / height);
      const numberOfRows = Math.max(baseBlurredRows, minimumBlurredRows);
      // render the needed skeleton rows, based on the number of rows needed
      renderSkeletonRows(height, numberOfRows);
    }
  }, [childRef.current, firstDataRef.current]);

  if (!hasData && !shouldRenderInnerComponent) {
    return null;
  }

  const lineType =
    data &&
    data.length &&
    Object.keys(data[0])?.filter((key) => !LIST_FIELD_NOT_RENDER_TO_LINE.includes(key))?.length > 1
      ? LineChartType.MULTIPLE_LINES
      : LineChartType.ONE_LINE;

  const chartLabels = getChartLabels(query.output);

  let max = 0;
  const modifyDataList = () => {
    return data?.map((_dataItem) => {
      return Object.keys(_dataItem).reduce<IModifiedChartDataToLineMetrics>(
        (acc, currentKey) => {
          if (LIST_FIELD_NOT_RENDER_TO_LINE.includes(currentKey)) {
            acc.header = {
              ...acc.header,
              [currentKey]: _dataItem[currentKey as keyof IChartData],
            };
          } else {
            const targetValue = _dataItem[currentKey as keyof IChartData];
            const parsedValue = targetValue && Number.parseFloat(targetValue.toString());

            acc.body = {
              ...acc.body,
              [currentKey]: formatFloatingNumber(parsedValue),
            };

            if (!targetValue) return acc;

            max = max > parsedValue ? max : parsedValue;
          }
          return acc;
        },
        {
          header: {},
          body: {},
        },
      );
    });
  };

  const renderPaywall = () => {
    if (!shouldRenderInnerComponent) return null;

    return (
      <LineChildOuterContainer hasData={hasData}>
        <div ref={clonedRowsRef}>
          {clonedRowArray.map((row, idx) => (
            <Fragment key={idx}>{row}</Fragment>
          ))}
        </div>
        <ChildContainer ref={childRef}>
          <Component {...child} />
        </ChildContainer>
      </LineChildOuterContainer>
    );
  };

  const renderList = modifyDataList()?.map(({ header, body }) => {
    const data: (string | number)[][] = Object.entries(body)
      .slice(0, 2)
      .sort((a, b) => {
        return (
          chartLabels.findIndex((label) => label.name === a[0]) - chartLabels.findIndex((label) => label.name === b[0])
        );
      });

    return {
      header,
      data,
    };
  });

  const firstHeader = renderList?.[0]?.header;

  const getCarrierData = (header?: IModifiedChartDataToLineMetrics['header']): IChartCarrierInfo | null => {
    if (!header) return null;

    switch (chart.query.table) {
      case PmAnalyticsQueryTable.Carrier:
        return {
          [KeysCanBeCompared.carrierName]: header.name,
          [KeysCanBeCompared.carrierLogo]: header.logoLink,
        };
      case PmAnalyticsQueryTable.MarketCarrier:
      case PmAnalyticsQueryTable.TradelaneCarrier:
        return {
          [KeysCanBeCompared.carrierName]: header.carrierName,
          [KeysCanBeCompared.carrierLogo]: header.carrierLogo,
        };
      default:
        return null;
    }
  };

  const getTitleType = () => {
    const carrierData = getCarrierData(firstHeader);
    if (carrierData?.carrierName) return LineTitleType.Carrier;
    if (firstHeader?.countryOrigin || firstHeader?.countryDestination) return LineTitleType.Country;
    if (firstHeader?.year) return LineTitleType.Year;
    return LineTitleType.Tradelane;
  };

  const renderSkeletonRows = (height: number | null, numberOfRows = 1) => {
    const titleType = getTitleType();
    const clonedRow = <Skeleton data={renderList && renderList[0]?.data} lineType={lineType} titleType={titleType} />;

    const clonedRows = new Array(numberOfRows).fill(clonedRow);
    setClonedRowArray(clonedRows);
    if (childRef.current) {
      childRef.current.style.height = hasData && height ? `${height * numberOfRows + 16}px` : 'calc(100% + 16px)';
      childRef.current.style.opacity = '1';
      childRef.current.style.pointerEvents = 'auto';
    }
  };

  const finalData = hasData ? renderList : [];

  const chartLayout = {
    chartLabels,
    type,
    title: tradelane ? (
      <TradeLane
        showDescription={showDescription}
        originCountryCode={tradelane?.originCountryCode}
        destinationCountryCode={tradelane?.destinationCountryCode}
      />
    ) : (
      title
    ),
    footer: timeframe && <Timeframe>Timeframe: {timeframe}</Timeframe>,
  };

  const renderLineItemTitle = (header: IModifiedChartDataToLineMetrics['header']) => {
    switch (getTitleType()) {
      case LineTitleType.Carrier: {
        const carrierData = getCarrierData(header);
        if (!carrierData) return null;

        const carrierInfo = getCarrierInfoByName(carrierData.carrierName as string, carriers);

        return (
          <CarrierName
            logo={carrierData?.carrierLogo}
            name={carrierInfo?.carrierDisplayName || carrierData?.carrierName}
            slug={carrierInfo?.slug || ''}
          />
        );
      }

      case LineTitleType.Country: {
        const hasCountryOriginCode = header.countryOrigin;
        const countryCode = hasCountryOriginCode ? header.countryOrigin : header.countryDestination;
        const countryName = hasCountryOriginCode ? header.countryOriginName : header.countryDestinationName;

        const marketSlug = countryName && getMarketSlugFromMarketName(countryName);
        const marketHref = countryName && replacePath(RouteName.Market, [marketSlug]);
        return countryCode && <CountryOption code={countryCode} href={marketHref} />;
      }

      case LineTitleType.Year: {
        return <YearText>{header.year}</YearText>;
      }

      default: {
        const { originCountryCode, destinationCountryCode } = getTradelane(header) || {};

        return <TradeLane originCountryCode={originCountryCode} destinationCountryCode={destinationCountryCode} />;
      }
    }
  };

  return (
    <ChartContainer {...chartLayout}>
      <div style={{ position: 'relative' }}>
        {finalData?.map(({ header, data }, index) => {
          return (
            <LineItemWrapper key={index} ref={firstDataRef}>
              <LineItem key={index} type={lineType} data={data} maxValue={max} title={renderLineItemTitle(header)} />
            </LineItemWrapper>
          );
        })}
        {renderPaywall()}
      </div>
    </ChartContainer>
  );
};

export default LineMetrics;
