import { SlugRoutes } from '@/constants';
import { ArticleTypeName } from '@/constants';
import { ReportsPopupType } from '@/interfaces';
import { usePathname } from 'next/navigation';
import { useRouter } from 'next/router';

const useReportPopupType = (): ReportsPopupType | undefined => {
  const pathNoParams = usePathname();
  const { query } = useRouter();

  if (pathNoParams === '/') return ReportsPopupType.homePage;

  if (pathNoParams.includes(SlugRoutes.companies)) return ReportsPopupType.carrierPages;

  if (query?.articleTypeSlug === ArticleTypeName.insights) return ReportsPopupType.insights;

  if (query?.articleTypeSlug === ArticleTypeName.reports) return ReportsPopupType.reports;

  if (query?.articleTypeSlug === ArticleTypeName.news) return ReportsPopupType.news;

  if (pathNoParams.includes(SlugRoutes.markets)) return ReportsPopupType.markets;
};

export default useReportPopupType;
