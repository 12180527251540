import { Button } from '@/components';
import { CookieKey } from '@/constants';
import { useReportPopupType } from '@/hooks';
import { ButtonSize, type IDownloadButtonProps } from '@/interfaces';
import { getFileNameFromFileUrl } from '@/utils';
import Cookies from 'js-cookie';
import { useState } from 'react';

const DownloadButton: React.FC<IDownloadButtonProps> = ({ closePopup, children, file }) => {
  const [downloading, setDownloading] = useState(false);
  const reportsPopupType = useReportPopupType();

  const storeFileNameToCookie = (fileName: string) => {
    if (!reportsPopupType) return;

    const prevDownloadFileNames = JSON.parse(Cookies.get(CookieKey.DownloadFileNames) || '{}');

    Cookies.set(
      CookieKey.DownloadFileNames,
      JSON.stringify({
        ...prevDownloadFileNames,
        [reportsPopupType]: fileName,
      }),
      { expires: 365 },
    );
  };

  const handleDownload = () => {
    if (!file?.url || downloading) return;
    const fileName = getFileNameFromFileUrl(file.url);
    setDownloading(true);

    fetch(file.url)
      .then(async (response) => response.blob())
      .then((blob) => {
        const link = document.createElement('a');
        const blobUrl = URL.createObjectURL(blob);
        link.href = blobUrl;
        link.download = fileName;
        link.click();
        URL.revokeObjectURL(blobUrl);
        closePopup();
        setDownloading(false);
        storeFileNameToCookie(fileName);
      })
      .catch(console.error)
      .finally(() => {
        setDownloading(false);
      });
  };

  return (
    <Button size={ButtonSize.Large} onClick={handleDownload} disabled={downloading}>
      {children}
    </Button>
  );
};

export default DownloadButton;
